import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import ConfigButton from "./ConfigButton";
import CrossButton from "./CrossButton";
import { DashboardContext } from "../../../../context/DashboardProvider";
import { MultiCropContext } from "../../../../context/MultiCropContext/MultiCropContext";

const HeadTile = ({ name, uid, col }) => {
  const { isDragButton } = useContext(DashboardContext);
  const { moveTileToMain } = useContext(MultiCropContext)
  const getNodeId = uid.split("@")[2];
  return (
    <Grid container>
      <Grid item xs={10}>
        <h6>{name}</h6>
      </Grid>

      <Grid item xs={2}>
        {isDragButton ? 
          <ConfigButton
          to={`/config/S@${uid}`}
          disabled={col === 0 ? true : false}
          />
        :
        <CrossButton onClick={() => moveTileToMain(uid)} hidden={isDragButton || col === 0} />
      }
        
      </Grid>

      {/* <Grid item xs={1}>
        <CrossButton onClick={() => moveTileToMain(uid)} hidden={isDragButton || col === 0} />
      </Grid> */}
    </Grid>
  );
};

export default HeadTile;
