import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../../context/UserProvider';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import HeadTile from './HeadTile';
import { db } from '../../../../config/firebase';

const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const allParameters = [
    "conductivity",
    "temperature",
    "ph",
    "humidity",
    "pressure",
    "co2",
    "nitrogen",
    "phosphorus",
    "potassium",
    "photosynthetic",
    "windSpeed",
    "rainGauge"
]

const namesToShow = [
	"EC",
    "Temperatura",
    "ph",
    "Humedad",
    "Presión",
    "CO2",
    "Nitrógeno",
    "Fósforo",
    "Potasio",
    "PAR",
    "Velocidad del viento",
    "Precipitación"
]
const soilTypes = [1,2,3];
const envTypes = [4,5,9,10]

export const NodeLoraWanTile = ({ data, style, col }) => {
	const { usuario, dataMacCan, getTimezoneOffsetInSeconds } = useContext(UserContext);
	const classes = useStyles();
	const uid = data.uid;
	const arrayDeCadenas = data.uid.split("@"); //0@1@2@3@4
	const [nodeName, setNodeName] = useState("Nodo");
	const [cardData, setCardData] = useState([])
	const [parameterTitles, setParameterTitles] = useState([])

	const useDocumentListener = (docPath) => {
		useEffect(() => {
		  // Obtiene la referencia al documento
		  const docRef = db.doc(docPath);
	  
		  // Se suscribe a los cambios del documento
		  const unsubscribe = docRef.onSnapshot((docSnapshot) => {
			if (docSnapshot.exists) {
			  data = docSnapshot.data();
			//   console.log("Esto es data:",data)
			  const allData = data.data
			  const obtainedData = [];
			  const namesOfParameters = [];
			  // Crear un array de objetos que contenga el nombre y el valor juntos
			  const parameters = [];
			  for (let index = 0; index < allData.length; index++) {
				if(Number(arrayDeCadenas[3]) === allData[index].id){
					for (const key in allData[index]) {
						if (allParameters.includes(key)) {
							// Obtener el valor del parámetro
							const value = allData[index][key];
							// Determinar el nombre del parámetro según el tipo
							let nameOfParameter = '';
							const paramIndex = allParameters.indexOf(key);
							const nameFound = namesToShow[paramIndex];
							if (soilTypes.includes(allData[index].type)) { // Tipo soil
							nameOfParameter = `${nameFound} del sustrato`;
							} else if (envTypes.includes(allData[index].type)) { // Tipo ambientales
							nameOfParameter = `${nameFound} del ambiente`;
							} else {
							nameOfParameter = nameFound;
							}

							// Añadir un objeto con el nombre y el valor
        					parameters.push({ name: nameOfParameter, value });
							
						}
					}
					// console.log("Esto es obtainedData:",obtainedData)
					// console.log("Esto es namesOfParameters:",namesOfParameters)
					// setCardData([...obtainedData])
					// setParameterTitles([...namesOfParameters])
					
				}
				
			  }
			  
			  // Ordenar el array de objetos alfabéticamente por el nombre
				parameters.sort((a, b) => a.name.localeCompare(b.name));

				// Extraer los datos ordenados para actualizar los estados
				const sortedNames = parameters.map(param => param.name);
				const sortedValues = parameters.map(param => param.value);

				// Actualizar el estado con los datos ordenados
				setParameterTitles(sortedNames);
				setCardData(sortedValues);
			  
			  // console.log("Esto es data:", data);
			} else {
			  console.log("¡No se encontró el documento!");
			}
		  }, (err) => {
			console.error("Error al escuchar el documento: ", err);
		  });
	  
		  // Función de limpieza que se ejecuta cuando el componente se desmonta
		  return () => unsubscribe();
	  
		}, [docPath]); // El efecto se vuelve a ejecutar si docPath cambia
	};
  
	const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}/sensors/dataSensors`;
  
	useDocumentListener(docPath);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Indicar que el componente está montado
		const obtenerDatos = async () => {
			try {
			  const arrayDeCadenas = uid.split("@");
			  const nodeid = arrayDeCadenas[2];
			  const addr2 = `${usuario.username}/loraDevices/nodes/${nodeid}/namesOfCards`;

			  const leerConfigNode = async () => {
				try {
				  const docRef = db.collection(addr2).doc("names");
				  const docSnap = await docRef.get();
			  
				  if (docSnap.exists) {
					const allSensorNames = docSnap.data().allNames;
					const idSensor = uid.split("@")[3];
					for (let index = 0; index < allSensorNames.length; index++) {
						if(Number(idSensor) === allSensorNames[index].id ) {
							// if(soilTypes.includes(allSensorNames[index].type)) {
							// 	assignName = `${allSensorNames[index].name}-Soil`
							// } else if(envTypes.includes(allSensorNames[index].type)){
							// 	assignName = `${allSensorNames[index].name}-Amb.`
							// } else {
							// 	assignName = `${allSensorNames[index].name}`
							// }
							
							setNodeName(allSensorNames[index].name)
							
						}
					}
					// Solo actualizar si el componente aún está montado
					if (isMountedRef.current) {
						
					}
					
				  } else {
					// El documento no existe.
					if (isMountedRef.current) {
					  console.log("No se encontró nombre del Nodo Lora");
					}
					
				  }
	
				} catch (error) {
				  console.error("Error al obtener el documento:", error);
				}
			  };
			  
			  //leerDatosDB();
			  leerConfigNode();
			} catch (error) {
			  console.log("obtenerDatos: error ", error);
			}
		  };
		  obtenerDatos();
		  return () => {
			isMountedRef.current = false; // Indicar que el componente se ha desmontado
		  };

	}, [uid, usuario.username])
    
  return (
	<Grid
	container
	direction="row"
	justifyContent="center"
	alignItems="center"  // Cambiado a "center" para centrar verticalmente.
	style={style}
	>
  	<HeadTile name={nodeName} uid={uid} col={col} />

  {cardData.length !== 0 &&
    cardData.map((value, index) => (
      <React.Fragment key={index}>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="center"  // Alineación centrada de los elementos hijos.
          justifyContent="center"
          className="parameter-grid"  // Clase CSS personalizada para aplicar estilos.
        >
          <Typography variant="subtitle2" gutterBottom>
            {parameterTitles[index]}
          </Typography>
          <h5>
            <span className="badge badge-dark value-badge">{value}</span>
          </h5>
        </Grid>
        {(index === 1 || index === 3 || index === 5) && (
          <Grid item xs={11}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </React.Fragment>
    ))}
</Grid>

  )
}
