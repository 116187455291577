import React, { useContext, useEffect, useState } from 'react'
import { Divider, Typography } from '@material-ui/core';
import { backGroundList } from '../../../../constants/globalConst';
import { UserContext } from '../../../../context/UserProvider';
import moment from 'moment';
import UpdatingButton from './ConfigComponents/UpdatingButton';
import DialogConfirm from './ConfigComponents/DialogConfirm';
import { db } from '../../../../config/firebase';


export const ConfigLoraWanNode = (propiedades) => {
	const data = propiedades.data;
  const ide = data.ide;
  const arrayDeCadenas = ide.split("@");
	//const nodeNumber = arrayDeCadenas[3];
  const macId = arrayDeCadenas[0]; 
	const { usuario, userTimezone } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [openWindow, setOpenWindow] = useState(false);
  const [newName, setNewName] = useState('');
	const [nodeNumber, setNodeNumber] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");
  const [nameDataSaved, setNameDataSaved] = useState([]);
  const [dataConfig, setDataConfig] = useState({
      nodeName: "-",
      nodeId: "-",
      fecha: "-",
      offset_values: ["-", "-", "-", "-", "-", "-", "-"],
      offset_signs: ["0", "0", "0", "0", "0", "0", "0"],
  });

	const handleNameChange = (e) => {
		setNewName(e.target.value);
	};

	const editar = async (e) => {
        e.preventDefault();
    
        if (!newName.trim()) {
          setError("Ingrese Nombre");
          return;
        }
        setError(null);
       
        setOpenWindow(true);
    };

	const agreeDialog = async () => {
        
        //setLoading(true)
        try {
          const arrayDeCadenas = ide.split("@");
          const nodeId = arrayDeCadenas[2];
          const typeSensor = arrayDeCadenas[3];
          const savePath = `${usuario.username}/loraDevices/nodes/${nodeId}/namesOfCards`;
          const docRef = db.collection(savePath).doc("names");

          const allNames = [...nameDataSaved];
          allNames.forEach((element) => {
            if(element.id === Number(typeSensor)) {
              element.lastUpdate = new Date().toUTCString();
              element.name = newName;
            }
          })
          
          // const nameObject = nameDataSaved.find(
          //   (element) => element.id === Number(typeSensor)
          // );
          // nameObject.name = newName;
          // nameObject.lastUpdate = new Date().toUTCString();
          await docRef.set({ allNames })



          //const item = { ...dataConfig, fecha: Date.now(), nodeName: newName ,uid: ide };
        //   const addr = `${usuario.username}/loraDevices/nodes/${nodeNumber}/configNode`;
    
        //   await db.collection(addr).doc("renderData").set({ item });
          
          //Envio de datos por MQTT
        //   for (let index = 0; index < arraySensorsData.length; index++) {
        //     procesarDatosSecuencialmente([arraySensorsData[index]]);
        //   }

          //Reset of values that changed
        //   for (let index = 0; index < arraySensorsData.length; index++) {
        //     arraySensorsData[index].changed = false;
        //   } 
          
          //setDataConfig({...dataConfig, nodeName: newName});
          setOpenWindow(false);
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
      let isMounted = true; // Bandera para verificar si el componente está montado
  
      const obtenerDatos = async () => {
        try {
          const arrayDeCadenas = ide.split("@");
          const nodeId = arrayDeCadenas[2];
          const typeSensor = arrayDeCadenas[3];
  
          const addr = `${usuario.username}/loraDevices/nodes/${nodeId}/namesOfCards`;
          const nodesAddr = `${usuario.username}/loraDevices/nodes`;
  
          const leerDatosDB = async () => {
            try {
              const docRef = db.collection(addr).doc("names");
              const docSnap = await docRef.get();
              const nodeNumDoc = await db.collection(nodesAddr).doc(nodeId).get();
  
              if (docSnap.exists && nodeNumDoc.exists) {
                const dataItem = docSnap.data().allNames;
                const nodeNumberObtained = nodeNumDoc.data().node;
                const currentName = dataItem.find(
                  (element) => element.id === Number(typeSensor)
                );
  
                if (isMounted) {
                  setNewName(currentName?.name); // Usa opcional chaining para evitar errores
                  setNodeNumber(nodeNumberObtained);
                  setLastUpdate(currentName?.lastUpdate);
                  console.log("Esto es lastUpdate:",currentName?.lastUpdate)
                  setNameDataSaved([...dataItem]);
                }
              } else {
                console.log("No se encontró el documento!");
              }
            } catch (error) {
              console.error("Error al obtener el documento:", error);
            }
          };
  
          await leerDatosDB();
        } catch (error) {
          console.error("Error en obtenerDatos:", error);
        }
      };
  
      obtenerDatos();
  
      return () => {
        isMounted = false; // Marca como desmontado en la limpieza
      };
    }, [ide, usuario.username]);

  return (
    <>
	<div className="container mt-3">
        <form onSubmit={editar}>
          <div className="row">
            <div className="col-12">
              <ul className="list-group">
                <li
                  className="list-group-item"
                  style={{ background: backGroundList }}
                >
                  <div className="row">
                    <div className=" col-10">
                      <h4 style={{ color: "white" }}>{newName}</h4>
                    </div>                   
                  </div>
                </li>
                <li className="list-group-item">

                  <div className="row">
                    <div className="col-6">Número de Nodo:</div>
                    <div className="col-6">{nodeNumber}</div>
                  </div>

                  <div className="row">
                    <div className="col-6">Nombre:</div>
                    <div className="col-6">
                      <input
                        type="text"
                        placeholder="Ingrese Nombre"
                        className="form-control mb-2"
                        onChange={handleNameChange}
                        value={newName}
                      ></input>
                    </div>
                  </div>

                  {dataConfig.fecha && (
                    <div className="row">
                      <div className="col-6">Última modificación:</div>
                      <div className="col-6">
                        {/* {moment(comp.fecha).format("llll")} */}
                        {/* {new Date(dataConfig.fecha).toString()} */}
                        {moment(lastUpdate).tz(userTimezone).format('ddd, D [de] MMM [de] YYYY, HH:mm [Hrs.]')}
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: '15px' }}>
                    <Divider />
                  </div>

                  {/* Offset Aqui */}

                </li>
              </ul>
            </div>
          </div>

          <div className="row ">
            {
              //como operador ternario
              error && (
                <div className="col-12 mt-3">
                  <div className="alert alert-danger alert-block">{error}</div>
                </div>
              )
            }
          </div>

          <UpdatingButton type="submit" disabled={false} />
        </form>

        
    </div>
    <DialogConfirm
    open={openWindow}
    handleClose={() => setOpenWindow(false)}
    agreeDialog={agreeDialog}
    title={"¿Está seguro que quiere continuar con esta operación?"}
    text={
      "Se realizará un cambio en el nombre de la tarjeta de información del Nodo."
    }
    />
    
  </>
  )
}
