import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MultiCropContext } from './MultiCropContext'
import { UserContext } from '../UserProvider';
import { db } from '../../config/firebase';
import moment from 'moment-timezone';


export const MulticropProvider = ({ children }) => {
	const {usuario} = useContext(UserContext);
	const [namesOfCrops, setNamesOfCrops] = useState([]);
	const [actualNumberOfCrops, setActualNumberOfCrops] = useState(1)
	const [tabValue, setTabValue] = useState(0)
	const [allTilesTest, setAllTilesTest] = useState([[]])
	const [hiddenCards, setHiddenCards] = useState([])
	const [weeksOfCrops, setWeeksOfCrops] = useState([])
	const [daysOfCrops, setDaysOfCrops] = useState([])
	const [timeZoneSelected, setTimeZoneSelected] = useState('')
	const [idOfCrops, setIdOfCrops] = useState([])
	const [openConfigNewCrop, setOpenConfigNewCrop] = useState(false);
	const [openEditWindow, setOpenEditWindow] = useState(false);
	const [placeOfCrops, setPlaceOfCrops] = useState([])
	const [crops, setCrops] = useState([])
	const [currentCoordinates, setCurrentCoordinates] = useState([]);
	const [horizontalMenuFlag, setHorizontalMenuFlag] = useState(false)
	

	const sendToCRcrop = async (data) => {
		try {
			const dataToSend = JSON.stringify(data);
			const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/processCropData', {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json',
				},
				body: dataToSend,
			});

			if (!response.ok) {
				throw new Error('Error en la solicitud: ' + response.statusText);
			}
			if(response.ok && data.action === "addCrop") {
				obtenerDatos();
			} else if(response.ok && data.action === "deleteCrop") {
				obtenerDatos();
				getHiddenCardsData();
			}
			return response.ok ? "allOK" : "error"
		} catch (error) {
			console.error("Error en el guardado de datos de Multicultivo:",error)
		}
	}

	const saveTimeZone = async() => {
		try {
			const docRef = db.collection(usuario.username).doc('multiCrop')
			const timeZone = timeZoneSelected
			await docRef.update({ timeZone })
		} catch (error) {
			console.error("Error en guardar zona horaria:",error)
		}
	}

	const getArrayOfUids = (dataArray) => {
		let uidsCol1 = [];
		let uidsCol2 = [];
		let uidsCol3 = [];
		for (let i = 1; i < dataArray.length; i++) {
			const columnData = dataArray[i].map((item) => {
				return item.kind !== "ghost" ? item.uid : "ghost"
			})
			const columnDataFiltered = columnData.filter((item) => item !== "ghost");
			switch (i) {
				case 1:
					uidsCol1 = columnDataFiltered
					break;
				case 2:
					uidsCol2 = columnDataFiltered
					break;
				case 3:
					uidsCol3 = columnDataFiltered
					break;
				default:
					break;
			}			
		}
		return {col_1: uidsCol1,col_2: uidsCol2, col_3: uidsCol3}
	}

	const saveNewTilesPosition = async() => {
		try {
			for (let index = 0; index < actualNumberOfCrops; index++) {
				const addr = `${usuario.username}/multiCrop/${idOfCrops[index]}`;
				const docRef = db.collection(addr).doc('cards');
				const render = getArrayOfUids(allTilesTest[index]);
				await docRef.set({ render })
			}

			const addressHiddenTiles = `${usuario.username}/multiCrop/hiddenCards`;
			const docRefHiddenTiles = db.collection(addressHiddenTiles).doc('cards')
			const render = []
			for (let index = 0; index < hiddenCards.length; index++) {
				const cardData = hiddenCards[index];
				if(cardData.kind !== "ghost") {
					render.push(cardData.uid)
				}
				
			}
			await docRefHiddenTiles.set({ render })
			
		} catch (error) {
			console.error("Error en el guradado de las Cards:",error)
		}
	}

	const addNewTabName = (newName) => {
		const helpArray = [...namesOfCrops];
		helpArray.push(newName)
		setNamesOfCrops([...helpArray])

	}

	const getDataUid = async (uid) => {
		const splitedUid = uid.split("@")
		if(splitedUid[1] === "node") {
			return { uid: uid, kind: "nodeLora" }
		} else if(splitedUid[1] === "nodelw") {
			return { uid: uid, kind: "nodeLoraWan" }
		} else {
		const address = `${usuario.username}/infoDevices/${splitedUid[0]}/${splitedUid[1]}/configModule`;
		const docRef = db.collection(address).doc(uid);
		const dataDoc = await docRef.get();
		const nameCard = dataDoc.data().item.name;
		const kindCard = dataDoc.data().item.kind;
		return { name: nameCard, kind: kindCard, uid: uid}
		}
		
	}

	const obtenerDatos = async () => {
		try {
			const documentRef = db.collection(usuario.username).doc('multiCrop');
			const arrayOfNames = []; 
			const arrayOfWeeks = [];
			const arrayOfDays = [];
			const arrayOfPlaces = [];
			const arrayOfCrops = [];
			const arrayOfCoordinates = [];
			const matrixOfUids = [];
			const matrixOfDataCards = [];  
			const cropsInfo = await documentRef.get();
			const generalData = cropsInfo.data()
			const cropsIds = generalData.listOfCrops
			const userTimezone = generalData.timeZone;
			const lastUpdated = moment.tz(generalData.lastUpdated, userTimezone);
			const now = moment.tz(userTimezone);
			setTimeZoneSelected(userTimezone)
			setIdOfCrops(generalData.listOfCrops)
			for (let index = 0; index < generalData.totalCrops; index++) {
				const addr = `${usuario.username}/multiCrop/${cropsIds[index]}`
				const documentData = db.collection(addr).doc('data')
				const cropData = await documentData.get()
				arrayOfNames.push(cropData.data().cropName)
				arrayOfPlaces.push(cropData.data().place)
				arrayOfCrops.push(cropData.data().cultivationOf)
				arrayOfCoordinates.push(cropData.data().cropCoordinates);
				if(now.diff(lastUpdated, 'hours') >= 24) {
					const currentDay = Number(cropData.data().growingDay)
					const currentWeek = Number(cropData.data().growingWeek)
					let newDay = currentDay + 1;
					let newWeek = currentWeek;
          			if (newDay > 7) {
						newWeek = currentWeek + 1;
						newDay = 1
					};
					await documentData.update({ growingDay: String(newDay), growingWeek: String(newWeek)})
					await documentRef.update({ lastUpdated: now.toISOString() })
					arrayOfDays.push(String(newDay));
					arrayOfWeeks.push(String(newWeek));
				} else {
					arrayOfDays.push(cropData.data().growingDay)
					arrayOfWeeks.push(cropData.data().growingWeek)
				}
				const documentCards = db.collection(addr).doc('cards');
				const cropCards = await documentCards.get();
				matrixOfUids.push(cropCards.data().render)
				
			}			
			
			for (let index = 0; index < matrixOfUids.length; index++) {
				const cardsForOneCrop = [[]]
				const data1 = [{uid:'1_0', kind:'ghost'}]
				const data2 = [{uid:'2_0', kind:'ghost'}]
				const data3 = [{uid:'3_0', kind:'ghost'}]
				const column1 = matrixOfUids[index].col_1;
				const column2 = matrixOfUids[index].col_2;
				const column3 = matrixOfUids[index].col_3;
				
				if(column1) {
					for (let i = 0; i < column1.length; i++) {
						const uid = column1[i];
						const dataOfUid = await getDataUid(uid)
						data1.push(dataOfUid)
					}
				}
				if(column2) {
					for (let i = 0; i < column2.length; i++) {
						const uid = column2[i];
						const dataOfUid = await getDataUid(uid)
						data2.push(dataOfUid)
					}
				}
				if(column3) {
					for (let i = 0; i < column3.length; i++) {
						const uid = column3[i];
						const dataOfUid = await getDataUid(uid)
						data3.push(dataOfUid)
					}
				}
				cardsForOneCrop.push(data1)
				cardsForOneCrop.push(data2)
				cardsForOneCrop.push(data3)
				
				matrixOfDataCards.push(cardsForOneCrop)
			}
			
			setAllTilesTest([...matrixOfDataCards])
			setNamesOfCrops([...arrayOfNames])
			setDaysOfCrops([...arrayOfDays])
			setWeeksOfCrops([...arrayOfWeeks])
			setPlaceOfCrops([...arrayOfPlaces])
			setCrops([...arrayOfCrops])
			setCurrentCoordinates([...arrayOfCoordinates]);
		} catch (error) {
			console.log("Error en la obtencion de pestañas de multicultivo:",error)
		}
	}

	const getHiddenCardsData = async () => {
		try {
			const addr = `${usuario.username}/multiCrop/hiddenCards`
			// const docRef2 = await db.collection(usuario.username).doc('renderMain').get()
			// const data = docRef2.data()
			// const render = [...data.render.col_0,...data.render.col_1,...data.render.col_2,...data.render.col_3]
			// await db.collection(addr).doc('cards').set({render})

			const documentRef = db.collection(addr).doc('cards');
			const docData = await documentRef.get();
			const uids = docData.data()
			const uidsRender = uids.render
			const dataOfUids = []
			for (let index = 0; index < uidsRender.length; index++) {
				const oneUidData = await getDataUid(uidsRender[index])
				dataOfUids.push(oneUidData)
			}
			setHiddenCards([...dataOfUids])
			setHorizontalMenuFlag(true);
		} catch (error) {
			console.error("Error en obtener las cards ocultas:",error)
		}
	}

	const moveTileToMain = useCallback((tileId) => {
		const newTiles = [...allTilesTest]; // Crea una copia del estado actual
		const newHiddenTiles = [...hiddenCards]
		let tileFound = null;
		let sourceIndex = null;
		const currentTabCards = newTiles[tabValue]
		for (let i = 1; i < currentTabCards.length; i++) {
			tileFound = currentTabCards[i].find(tile => tile.uid === tileId);
			if (tileFound) {
				sourceIndex = i; // Guardar el índice del sub-arreglo donde se encontró
				break; // Salir del ciclo una vez encontrado el tile
			}
		}
		if (tileFound && sourceIndex !== null) {
			// Eliminar el tile del sub-arreglo original
			currentTabCards[sourceIndex] = currentTabCards[sourceIndex].filter(tile => tile.uid !== tileId);
			newTiles[tabValue] = [...currentTabCards]
			setAllTilesTest([...newTiles])
			// Añadir el tile al sub-arreglo en la posición 0
			newHiddenTiles.splice(0,0,tileFound)
			setHiddenCards([...newHiddenTiles])
		}
		
	}, [setAllTilesTest, allTilesTest,hiddenCards,tabValue]);

	useEffect(() => {
	  if(usuario.username) {
		getHiddenCardsData();
		obtenerDatos();
	  }
	}, [usuario.username])

	useEffect(() => {
		try{
			const refreshDashboard = async (data) => {
				await obtenerDatos();
				setOpenConfigNewCrop(false);
			}

			const refreshHiddenCards = async() => {
				setTabValue(0)
				await getHiddenCardsData();
				
			}

			const addr = usuario.username+'/multiCrop';
			const unsubscribe = db.doc(addr).onSnapshot(snapshot => {
			  const data = snapshot.data();
			  if(data){
				
				if(data.totalCrops !== actualNumberOfCrops){
					if(Number(data.totalCrops) > actualNumberOfCrops) {
						refreshDashboard(data);
						
					} else {
						refreshHiddenCards();
					}
					setActualNumberOfCrops(Number(data.totalCrops));
					
				}
			  }
			  
			})
			// Limpiar el listener al desmontar el componente
			return () => unsubscribe();
		  
		  
		} catch(error) {
		  console.error("Error al detectar la respuesta del embebido:",error)
		}
	  }, [usuario.username,actualNumberOfCrops])
	

  return (
	<MultiCropContext.Provider 
	value={{namesOfCrops, tabValue, allTilesTest, actualNumberOfCrops, hiddenCards,weeksOfCrops,daysOfCrops,
		timeZoneSelected,idOfCrops,openConfigNewCrop,openEditWindow, placeOfCrops,crops,currentCoordinates,
		horizontalMenuFlag,
		setOpenEditWindow, setOpenConfigNewCrop,setCrops,setPlaceOfCrops, 
		setTimeZoneSelected,addNewTabName, sendToCRcrop, setTabValue, setAllTilesTest,
		saveNewTilesPosition, setHiddenCards, moveTileToMain,saveTimeZone,setWeeksOfCrops,setDaysOfCrops,
		setNamesOfCrops,setCurrentCoordinates}}>
		{ children }
	</MultiCropContext.Provider>
  )
}
