import React from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

/*Material UI*/
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Button, CircularProgress, Grid, Input, ListItemText, Typography, makeStyles } from "@material-ui/core";
/*Iconos*/
//import CheckIcon from "@material-ui/icons/Check";
/**Crear grafica */

import { UserContext } from "../../../context/UserProvider";

import TransitionAlerts from "../TransitionAlerts";
import {
  HIGHCHART_OPT_SPANISH,
  ALL_KINDS,
} from "../../../constants/globalConst";
import Loader from "../Loader";
import TableDataChart from "./TableDataChart";

import ResponsiveDateTimeInterval from "../ResponsiveDateTimeInterval";
import { green } from "@material-ui/core/colors";
Highcharts.setOptions(HIGHCHART_OPT_SPANISH);
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const defaultOpenAlert = { open: null, type: null, tittle: null, txt: null };

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
     marginLeft: 12,
  },
 
}));

const GraphicDataDevices = ({
  inputDevices,
  outDevices,
  manualDevices,
  getDataFromDB,
  seriesModifier,
  setSeriesModifier,
  component,
}) => {
  const classes = useStyles();
  const { usuario, getTimezoneOffsetInSeconds } = React.useContext(UserContext);
  const [openAlert, setOpenAlert] = React.useState(defaultOpenAlert);

  const [propSelectName, setPropSelectName] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [dataTable, setDataTable] = React.useState([]);

  const [enableLoader, setEnableLoader] = React.useState(false);

  const [optionsHighChart, setOptionsHighChart] = React.useState();
  const [nColor, setNColor] = React.useState(
    Math.floor(Math.random() * 10) - 1
  );
  const diaActual = new Date();
  const diaAyer = new Date(diaActual);
  diaAyer.setDate(diaAyer.getDate() - 7);
  const [fechaIni, setFechaIni] = React.useState(diaAyer.toISOString());
  const [fechaFin, setFechaFin] = React.useState(diaActual.toISOString());
  const [flgChangeDate, setflgChangeDate] = React.useState(false);
  const [uidsSelected, setUidsSelected] = React.useState([]);
  const [loadingExport, setLoadingExport] = React.useState(false);

  React.useEffect(() => {
    //console.log(seriesModifier)

    const insertArray_BtoA_OrderByTime = (arrayA, arrayB) => {
      let cloneArrayA = [...arrayA];
      const indexTime = 0;
      const INDEX_VALI_NOT_FOUND = -1;
      const N_OF_DELETES = 0;
      const isLargeNumber = (element) => element[indexTime] > arrayB[indexTime];
      let index = cloneArrayA.findIndex(isLargeNumber);
      if (index === INDEX_VALI_NOT_FOUND) {
        index = cloneArrayA.length;
      }

      cloneArrayA.splice(index, N_OF_DELETES, arrayB);

      return cloneArrayA;
    };

    const actionOnAdd = (serieToModify) => {
      const formData = seriesModifier.formData;
      const dataToMakeAction = [formData.date, parseFloat(formData.value)];
      const newDataSerie = insertArray_BtoA_OrderByTime(
        serieToModify.data,
        dataToMakeAction
      );

      serieToModify = { ...serieToModify, data: newDataSerie };
      const editSeries = series.map((item) =>
        item.label === serieToModify.label ? serieToModify : item
      );
      return editSeries;
    };

    const deleteBetweenTimes = (serieToModify) => {
      const formData = seriesModifier.formData;
      const timeInit = formData.fromDateDelete;
      const timeFin = formData.date;
      const indexTime = 0;
      const isNotBetweenTimes = (item) =>
        !(item[indexTime] >= timeInit && item[indexTime] <= timeFin);

      const newDataSerie = serieToModify.data.filter(isNotBetweenTimes);

      serieToModify = { ...serieToModify, data: newDataSerie };
      const editSeries = series.map((item) =>
        item.label === serieToModify.label ? serieToModify : item
      );
      return editSeries;
    };

    const deleteAllSerie = () => {
      const formData = seriesModifier.formData;
      const editSeries = series.filter((item) => item.label !== formData.name);
      return editSeries;
    };

    const actionOnDeleteByTime = (serieToModify) => {
      const checkedEraseAll = seriesModifier.formData.checkedEraseAll;
      if (checkedEraseAll) {
        return deleteAllSerie();
      } else {
        return deleteBetweenTimes(serieToModify);
      }
    };

    const actionOnDelete = (serieToModify, dataForm) => {
      const value = parseFloat(dataForm.value);
      const date = dataForm.date;
      const indexTime = 0;
      const indexValue = 1;

      const isNotTheDataSelectedToDelete = (item) =>
        !(item[indexTime] === date && item[indexValue] === value);

      const newDataSerie = serieToModify.data.filter(
        isNotTheDataSelectedToDelete
      );

      serieToModify = { ...serieToModify, data: newDataSerie };
      const editSeries = series.map((item) =>
        item.label === serieToModify.label ? serieToModify : item
      );
      console.log(editSeries);
      return editSeries;
    };

    const seriesModifierExtern = () => {
      if (!seriesModifier) {
        return;
      } //No fue declarado en el componente

      if (!seriesModifier.action) {
        return;
      } //Se encuentra en null
      let serieToModify = series.find(
        (item) => item.label === seriesModifier.formData.name
      );
      if (!serieToModify) {
        return;
      } //No hay serie en la gráfica

      let editSeries = [...series];
      switch (seriesModifier.action) {
        case "add":
          editSeries = actionOnAdd(serieToModify);
          break;

        case "deleteByTime":
          editSeries = actionOnDeleteByTime(serieToModify);
          break;

        case "update":
          const { lastData } = seriesModifier;
          const editSeries1 = actionOnDelete(serieToModify, lastData);
          const serieToModify1 = editSeries1.find(
            (item) => item.label === seriesModifier.formData.name
          );
          editSeries = actionOnAdd(serieToModify1);
          break;

        case "delete":
          const { formData } = seriesModifier;
          editSeries = actionOnDelete(serieToModify, formData);
          break;

        default:
          break;
      }
      setSeries(editSeries);
    };

    seriesModifierExtern();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesModifier]);

  //Highcharts.setOptions(HIGHCHART_OPT_SPANISH);

  const updateTable = (este) => {
    const series = este.series;

    const clone = series.map((serie) => ({
      name: serie.userOptions.label,
      label: serie.userOptions.label,
      color: serie.color,
      points: serie.processedYData,
      step: serie.userOptions.step,
      yAxis: serie.userOptions.yAxis,
      uid: serie.userOptions.uid,
    }));
    setDataTable(clone);
  };

  const aumentaColor = () => {
    if (nColor < 9) setNColor(nColor + 1);
    else setNColor(0);
  };

  React.useEffect(() => {
    const optionsChart = () => {
      const opt = {
        time: {
          // timezoneOffset: new Date().getTimezoneOffset(),
          timezoneOffset: getTimezoneOffsetInSeconds()
        },
        chart: {
          zoomType: "xy",
          events: {
            redraw: function (e) {
              console.log("[chart redraw]");
              updateTable(this);
            },
          },
        },
        subtitle: {
          text: "Seleccione una sección para realizar zoom",
        },
        /*
        tooltip: {
        	split: true,
          shared: false,
          formatter: function () {
            
            return this.points.reduce(function (s, point) {
                    return s + '<br/>' + point.series.name + ': ' +
                        point.y ;
                }, '<b>' + this.x + '</b>');
            },
        },
        */
        title: {
          text: "",
        },
        xAxis: {
          crosshair: true,
          type: "datetime",
          labels: {
            rotation: 315,
            overflow: "justify",
            style: {
              fontSize: "15px",
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "", //Digital
            },
            opposite: false,
          },
          {
            title: {
              text: "", //Entradas
              style: { color: "green" },
            },
            opposite: true,
            //alignTicks: false,
            labels: {
              format: "{value} ",
            },
          },
        ],
        credits: {
          enabled: false,
        },
        navigation: { menuItemStyle: { fontSize: "15px" } },
        exporting: {
          filename: `Graficas_Comparativa_Param`,
          sourceWidth: 1280,
          sourceHeight: 720,
          chartOptions: { subtitle: null },
        },
        series: series ?? [],
      };
      return opt;
    };

    setOptionsHighChart(optionsChart());
  }, [series]);

  const close = () => {
    setOpenAlert(defaultOpenAlert);
  };

  const addSerieToGraph = (newSerie) => {
    setSeries([...series, newSerie]);
  };

  const addColToTable = (newSerie) => {
    if (!newSerie) {
      console.log("error:1");
      return;
    }
    setDataTable([
      ...dataTable,
      {
        name: newSerie.label,
        label: newSerie.label,
        color: "blue",
        points: [], //Por que si puede cargar todos pero depende del navegador date
        step: newSerie.step,
        yAxis: newSerie.yAxis,
        uid: newSerie.uid,
      },
    ]);
  };

  const deleteTableCol = (m) => {
    const otherSeries = dataTable.filter((serie) => serie.label !== m);

    setDataTable(otherSeries);
  };

  const deleteSerieFromGraph = (m) => {
    let otherSeries = series.filter((serie) => serie.label !== m);
    setSeries(otherSeries);
  };

  const setFullfillColor = (ini, fin) => {
    const js = {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [
          0,
          Highcharts.color(Highcharts.getOptions().colors[nColor])
            .setOpacity(ini)
            .get("rgba"),
        ],
        [
          1,
          Highcharts.color(Highcharts.getOptions().colors[nColor])
            .setOpacity(fin)
            .get("rgba"),
        ],
      ],
    };
    aumentaColor();
    return js;
  };

  const setDigitalLine = (sensor, chartData) => {
    const newSerie = {
      name: sensor.label,
      label: sensor.label,
      data: chartData,
      step: "left",
      yAxis: 0,
      uid: sensor.uid,

      color: Highcharts.color(Highcharts.getOptions().colors[nColor])
        .setOpacity(1)
        .get("rgba"),
      type: "area",
      fillColor: setFullfillColor(0.2, 0),
    };

    return newSerie;
  };

  const setAnalogLine = (sensor, chartData) => {
    const newSerie = {
      name: sensor.label,
      label: sensor.label,
      data: chartData,
      yAxis: 1,
      uid: sensor.uid,

      color: Highcharts.color(Highcharts.getOptions().colors[nColor])
        .setOpacity(1)
        .get("rgba"),
      type: "areaspline",
      fillColor: setFullfillColor(0.2, 0),
    };

    return newSerie;
  };

  const settingAlarm = (uid) => {
    let alertjs = {};
    alertjs = {
      open: true,
      type: "error",
      tittle: "Error",
      txt: "No hay datos",
    };

    const isSensor = inputDevices.find((item) => item.uid === uid);
    let itemName = "";
    if (isSensor) {
      itemName = isSensor.label;
      alertjs["txt"] = "No hay datos registrados del sensor " + itemName;
    } else {
      const isSalida = outDevices.find((item) => item.uid === uid);
      itemName = isSalida.label;
      alertjs["txt"] = "No hay datos registrados de la salida " + itemName;
    }
    setOpenAlert(alertjs);
  };

  async function getDataset(user, uid, fIni, fFin, tipo) {
    const valArray = await getDataFromDB(user, uid, fIni, fFin, tipo);
    return valArray;
  }

  const deleteDeviceAllStates = (label) => {
    deleteSerieFromGraph(label); //Delet data graph
    deleteTableCol(label);
    setPropSelectName(() => propSelectName.filter((item) => item !== label)); //delete from selected array name
  };

  async function getSerie(sensor, type) {
    setOpenAlert(defaultOpenAlert);
    //console.log("[getSerie] propSelectName: ", propSelectName);
    const foundSelected = propSelectName.find((item) => item === sensor.label);
    if (foundSelected) {
      //Funciona para unclik y doble clik, para que borre solo cuando ya cargo todo
      deleteDeviceAllStates(sensor.label);
      return;
    }
    
    let tipo = type==="manual" ? "manual" : "modulo"

    let chartData = [];
    setEnableLoader(true);
    chartData = await getDataset(
      usuario.username,
      sensor.uid,
      fechaIni,
      fechaFin,
      tipo
    );
    setEnableLoader(false);
    setUidsSelected(prevValues => [...prevValues, sensor.uid])

    if (chartData.length === 0) {
      //Cuando regresa el array 0
      deleteDeviceAllStates(sensor.label);
      settingAlarm(sensor.uid);
      return;
    }

    if (type === "out") {
      for (let i = 0; i < chartData.length; i++) {
        let number = parseInt(chartData[i][1]);
        let dataTrunc = Math.trunc(number);
        chartData[i][1] = dataTrunc;
      }

      addSerieToGraph(setDigitalLine(sensor, chartData));
      //addColToTable(setDigitalLine(sensor, chartData));
    } else if (type === "in") {
      let newSerie;
      const dataSensor = sensor.uid.split("@");

      if (dataSensor[2] === ALL_KINDS.IN_SWITCH) {
        newSerie = setDigitalLine(sensor, chartData);
      } else {
        newSerie = setAnalogLine(sensor, chartData);
      }
      addSerieToGraph(newSerie);
      //addColToTable(newSerie);
    }
  }

  var listOutDevices =
    outDevices &&
    outDevices.map((s, i) => (
      <MenuItem
        key={s.uid + "," + i}
        value={s.label}
        onClick={() => getSerie(s, "out")}
      >
        {s.label}
      </MenuItem>
    ));

  var listInputDevices =
    inputDevices &&
    inputDevices.map((s, i) => (
      <MenuItem
        key={s.uid + "," + i}
        value={s.label}
        onClick={() => getSerie(s, "in")}
      >
        {s.label}
      </MenuItem>
    ));
    
  var  listManualDevices = 
  manualDevices && 
  manualDevices.map((s, i) => (
    <MenuItem
      key={s.uid + "," + i}
      value={s.label}
      onClick={() => getSerie(s, "manual")}
    >
      {s.label}
    </MenuItem>
  ));

  const handleChange = (event) => {
    setPropSelectName(event.target.value);
  };

  React.useEffect(() => {
    const newSelect = series.map((item) => item.label);
    setPropSelectName(newSelect);
  }, [series]);

  React.useEffect(() => {
    if (flgChangeDate && propSelectName.length > 0) {
      setPropSelectName([])
      setDataTable([])
      setSeries([]);
      setflgChangeDate(false);
    }
  }, [flgChangeDate, propSelectName]);

  const handleDateChange = (date, id) => {
    let aux = new Date(date._d.getTime()).toISOString();
    console.log(
      "[HandleDateChange] Date: ",
      date,
      " fecha: ",
      date._d.getTime(),
      "aux",
      aux
    );
    if (id === "time-picker-ini") {
      console.log(" [HandleDateChange] fechaIni colocada: ", aux);
      if (aux !== fechaIni) {
        setflgChangeDate(true);
      }
      setFechaIni(aux);
    } else if (id === "time-picker-fin") {
      console.log(" [HandleDateChange] fechaFin colocada");
      if (aux !== fechaFin) {
        setflgChangeDate(true);
      }
      setFechaFin(aux);
    }
  };

  const handleExportData = async () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const data = {
    username: usuario.username,
    uids_array: uidsSelected,
    startDate: fechaIni,
    endDate: fechaFin,
    uids_titles: propSelectName,
    timeZone: timeZone
  };
  try {
    setLoadingExport(true);
    const dataToSend = JSON.stringify(data);
    console.log("Esto es dataToSend:",dataToSend)
    //console.log("Esto es timeZone:", timeZone)
    const response = await fetch('https://us-central1-gapy-c999c.cloudfunctions.net/exportData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: dataToSend,
    });

    if (!response.ok) throw new Error('Network response was not ok.');

    // Obtiene la respuesta como Blob
    const blob = await response.blob();
    // Crea una URL para el Blob
    const downloadUrl = window.URL.createObjectURL(blob);
    // Crea un enlace temporal y simula un clic para descargar
    const a = document.createElement('a');
    a.href = downloadUrl;
    // Personaliza el nombre del archivo para la descarga, si es necesario
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = `${usuario.username}_datos.csv`; // Nombre por defecto
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="?(.*?)"?($|;)/);
      if (fileNameMatch) {
        filename = fileNameMatch[1];
      }
    }
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    // Limpia removiendo el enlace y liberando la URL del Blob
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
    setLoadingExport(false);
  } catch (error) {
    console.error('Error:', error);
  }
};


  return (
    <div>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Grid item xs={1}>
          <Typography variant="h5" gutterBottom>
            Desde
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <ResponsiveDateTimeInterval
            id="time-picker-ini"
            selectedDate={fechaIni}
            handleDateChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h5" gutterBottom>
            hasta
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <ResponsiveDateTimeInterval
            id="time-picker-fin"
            selectedDate={fechaFin}
            handleDateChange={handleDateChange}
          />
        </Grid>

        <Grid item xs={3}>
          {(outDevices || inputDevices) && (
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel htmlFor="grouped-select">Dispositivos</InputLabel>

              <Select
                defaultValue=""
                id="grouped-select"
                multiple
                value={propSelectName}
                onChange={handleChange}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {(inputDevices.length === 0 && outDevices.length === 0 && manualDevices.length === 0) && (
                  <MenuItem value="" disabled>
                    Cargando lista...
                  </MenuItem>
                )}
                {inputDevices.length > 0 && (
                  <MenuItem key="HeadOut" value="Salidas" disabled>
                    <ListItemText>Salidas</ListItemText>
                  </MenuItem>
                )}
                {listOutDevices}

                {outDevices.length > 0 && (
                  <MenuItem key="HeadIn" value="Entradas" disabled>
                    <ListItemText>Entradas</ListItemText>
                  </MenuItem>
                )}
                {listInputDevices}

                {manualDevices.length > 0 && (
                  <MenuItem key="HeadIn" value="Entradas" disabled>
                    <ListItemText>Bitácora (Manuales)</ListItemText>
                  </MenuItem>
                )}
                {listManualDevices}
              </Select>
            </FormControl>
          )}
        </Grid>

        <Grid item xs={6}>
        <Button variant="contained" color="primary" disabled={propSelectName.length === 0 || loadingExport} onClick={handleExportData}>
          Exportar datos
        </Button>
        {loadingExport && <CircularProgress size={35} className={classes.buttonProgress} />}

        </Grid>

        <Grid item xs={6}>
          <Loader loading={enableLoader} />
        </Grid>

        <Grid item xs></Grid>

        <Grid item xs>
          {component && component.alert}
        </Grid>
      </Grid>

      <Grid container spacing={1} direction="column">
        <Grid item xs>
          <TransitionAlerts data={openAlert} onClick={close} enableHead />
        </Grid>

        <Grid item xs>
          {series.length > 0 && (
            <HighchartsReact
              highcharts={Highcharts}
              //constructorType={"stockChart"}
              options={optionsHighChart}
            />
          )}
        </Grid>

        <Grid item xs>
          <TableDataChart dataTable={dataTable} />
        </Grid>
      </Grid>
    </div>
  );
};

export default GraphicDataDevices;
export { GraphicDataDevices };
